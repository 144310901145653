/** @jsx jsx */
import { jsx, Styled, Textarea } from 'theme-ui';
import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../components/layout';
import { getTranslate } from 'react-localize-redux';
import {
  hideAllNotifications,
  showNotification,
  SEVERITY,
} from '../state/notifications';
import * as yup from 'yup';
import FormField from '../components/FormField';
import { Form, Field } from 'formik';
import { Container } from '../components';
import RadioFieldGroup from '../components/RadioFieldGroup';
import CheckboxFieldGroup from '../components/CheckboxFieldGroup';
import RadioField from '../components/RadioField';
import CheckboxField from '../components/CheckboxField';
import { withFormik } from 'formik';
import RecaptchaButton from '../components/RecaptchaButton';
import * as api from '../utils/api';
import { handleFormValidationError } from '../utils/formError';
import { locNavigate } from '../state/session';
import Spinner from '../components/Spinner';
import * as queryString from 'query-string';
import { Select } from '../components/Select';
import * as analytics from '../utils/analytics';

const jobApplicationSchema = translate =>
  yup.object().shape({
    type: yup
      .string()
      .oneOf(
        [
          translate('jobApplicationForm.types.open'),
          translate('jobApplicationForm.types.listed'),
        ],
        translate('jobApplicationForm.message.invalidType')
      )
      .required(translate('jobApplicationForm.message.requiredField')),
    position: yup.string(),
    name: yup
      .string()
      .required(translate('jobApplicationForm.message.requiredField')),
    streetAddress: yup
      .string()
      .required(translate('jobApplicationForm.message.requiredField')),
    post: yup
      .string()
      .required(translate('jobApplicationForm.message.requiredField')),
    phone: yup
      .string()
      .required(translate('jobApplicationForm.message.requiredField')),
    email: yup
      .string()
      .email(translate('jobApplicationForm.message.invalidEmail')),
    education: yup.string(),
    jobExperience: yup.string(),
    office: yup.string(),
    role: yup
      .string()
      .oneOf(
        [
          translate('jobApplicationForm.roles.customerService'),
          translate('jobApplicationForm.roles.serviceAdvisor'),
          translate('jobApplicationForm.roles.driver'),
          translate('jobApplicationForm.roles.warehouseWorker'),
          translate('jobApplicationForm.roles.expert'),
        ],
        translate('jobApplicationForm.message.invalidRole')
      )
      .required(translate('jobApplicationForm.message.requiredField')),
    duration: yup
      .array()
      .of(
        yup
          .string()
          .oneOf(
            [
              translate('jobApplicationForm.durations.temporary'),
              translate('jobApplicationForm.durations.permanent'),
              translate('jobApplicationForm.durations.partTime'),
              translate('jobApplicationForm.durations.fullTime'),
            ],
            translate('jobApplicationForm.message.invalidDuration')
          )
      )
      .ensure()
      .required(translate('jobApplicationForm.message.requiredField')),
    application: yup
      .string()
      .required(translate('jobApplicationForm.message.requiredField')),
    recaptcha: yup.string().required(),
  });

const JobApplicationForm = ({
  translate,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  errors,
  nocaptcha,
}) => {
  return (
    <Form>
      <RadioFieldGroup
        id="type"
        error={errors.type}
        label={translate('jobApplicationForm.type')}
      >
        <Field
          component={RadioField}
          name="type"
          checked="checked"
          id={translate('jobApplicationForm.types.open')}
          label={translate('jobApplicationForm.types.open')}
        />
        <Field
          component={RadioField}
          name="type"
          id={translate('jobApplicationForm.types.listed')}
          label={translate('jobApplicationForm.types.listed')}
        />
      </RadioFieldGroup>
      <FormField
        name="position"
        label={translate('jobApplicationForm.position')}
      />
      <FormField name="name" label={translate('jobApplicationForm.name')} />
      <FormField
        name="streetAddress"
        label={translate('jobApplicationForm.streetAddress')}
      />
      <FormField name="post" label={translate('jobApplicationForm.post')} />
      <FormField name="phone" label={translate('jobApplicationForm.phone')} />
      <FormField name="email" label={translate('jobApplicationForm.email')} />
      <FormField
        name="education"
        as={Textarea}
        rows={4}
        label={translate('jobApplicationForm.education')}
      />
      <FormField
        name="jobExperience"
        as={Textarea}
        rows={4}
        label={translate('jobApplicationForm.jobExperience')}
      />
      <FormField name="office" label={translate('jobApplicationForm.office')} />
      <FormField
        as={Select}
        name="role"
        label={translate('jobApplicationForm.role')}
      >
        <option value={translate('jobApplicationForm.roles.customerService')}>
          {translate('jobApplicationForm.roles.customerService')}
        </option>
        <option value={translate('jobApplicationForm.roles.serviceAdvisor')}>
          {translate('jobApplicationForm.roles.serviceAdvisor')}
        </option>
        <option value={translate('jobApplicationForm.roles.driver')}>
          {translate('jobApplicationForm.roles.driver')}
        </option>
        <option value={translate('jobApplicationForm.roles.driver')}>
          {translate('jobApplicationForm.roles.driver')}
        </option>
        <option value={translate('jobApplicationForm.roles.expert')}>
          {translate('jobApplicationForm.roles.expert')}
        </option>
      </FormField>
      <CheckboxFieldGroup
        id="duration"
        error={errors.duration}
        label={translate('jobApplicationForm.duration')}
      >
        <Field
          component={CheckboxField}
          name="duration"
          multiple={true}
          setFieldValue={setFieldValue}
          id={translate('jobApplicationForm.durations.temporary')}
          label={translate('jobApplicationForm.durations.temporary')}
          value={translate('jobApplicationForm.durations.temporary')}
        />
        <Field
          component={CheckboxField}
          name="duration"
          multiple={true}
          setFieldValue={setFieldValue}
          id={translate('jobApplicationForm.durations.permanent')}
          label={translate('jobApplicationForm.durations.permanent')}
          value={translate('jobApplicationForm.durations.permanent')}
        />
        <Field
          component={CheckboxField}
          name="duration"
          multiple={true}
          setFieldValue={setFieldValue}
          id={translate('jobApplicationForm.durations.partTime')}
          label={translate('jobApplicationForm.durations.partTime')}
          value={translate('jobApplicationForm.durations.partTime')}
        />
        <Field
          component={CheckboxField}
          name="duration"
          multiple={true}
          setFieldValue={setFieldValue}
          id={translate('jobApplicationForm.durations.fullTime')}
          label={translate('jobApplicationForm.durations.fullTime')}
          value={translate('jobApplicationForm.durations.fullTime')}
        />
      </CheckboxFieldGroup>
      <FormField
        name="application"
        as={Textarea}
        rows={4}
        label={translate('jobApplicationForm.application')}
      />
      <RecaptchaButton
        buttonText="jobApplicationForm.send"
        translate={translate}
        handleSubmit={handleSubmit}
        isSubmitting={isSubmitting}
        setFieldValue={setFieldValue}
        nocaptcha={nocaptcha}
      />
    </Form>
  );
};

const JobApplicationFormik = withFormik({
  mapPropsToValues: ({ vo, translate }) =>
    Object.assign({
      type: vo.type || translate('jobApplicationForm.types.open'),
      position: vo.position || '',
      name: vo.name || '',
      streetAddress: vo.streetAddress || '',
      post: vo.post || '',
      phone: vo.phone || '',
      email: vo.email || '',
      education: vo.education || '',
      jobExperience: vo.jobExperience || '',
      office: vo.office || '',
      role: vo.role || translate('jobApplicationForm.roles.customerService'),
      duration: vo.duration || [],
      application: vo.application || '',
      recaptcha: vo.recaptcha || '',
    }),
  validationSchema: ({ translate }) => {
    return jobApplicationSchema(translate);
  },
  handleSubmit: (values, { props: { onSubmit }, ...actions }) => {
    onSubmit(values, actions);
  },
  displayName: 'JobApplicationInputForm',
})(JobApplicationForm);

const JobApplicationPage = ({ pageContext, location }) => {
  analytics.usePageCategory('lomakkeet');
  const translate = getTranslate(useSelector(state => state.localize));
  const locale = useSelector(state => state.session.locale);
  const [vo, setVO] = useState({});
  const dispatch = useDispatch();
  const [showSpinner, setShowSpinner] = useState(false);
  const queryParams = queryString.parse(location.search);
  const { nocaptcha } = queryParams;

  const onSubmit = useCallback(
    async (vo, { setSubmitting }) => {
      setShowSpinner(true);
      dispatch(hideAllNotifications());
      try {
        await api.sendJobApplication({ ...vo, locale });
        dispatch(
          locNavigate('/thank-you', '', {
            state: { message: translate('jobApplicationForm.message.success') },
          })
        );
        //dispatch(showNotification('jobApplicationForm.message.success', SEVERITY.INFO));
        setShowSpinner(false);
        setSubmitting(false);
      } catch (error) {
        setShowSpinner(false);
        setSubmitting(false);
        if (handleFormValidationError(error, dispatch, translate)) {
          return;
        }
        console.error(error);
        dispatch(
          showNotification('jobApplicationForm.message.failed', SEVERITY.ERROR)
        );
      }
      setVO({});
      window && window.scrollTo(0, 0);
    },
    [dispatch, locale, translate]
  );
  const formProps = { vo, onSubmit, translate, nocaptcha };

  return (
    <Layout
      title={translate('jobApplicationForm.title')}
      paths={pageContext.paths}
      locale={pageContext.locale || 'en'}
    >
      <Container
        sx={{
          maxWidth: 1024,
          p: [3, 4],
        }}
      >
        <Styled.h1>{translate('jobApplicationForm.title')}</Styled.h1>
        <JobApplicationFormik key={locale} {...formProps} />
        {showSpinner && <Spinner size="medium" position="fixed" />}
      </Container>
    </Layout>
  );
};

export default JobApplicationPage;
